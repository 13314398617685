<template>
  <b-container>
    <validation-observer
      #default="{ invalid }"
      ref="simpleRules"
    >
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-card>
          <b-row>
            <b-col
              xl="6"
              lg="6"
              md="12"
            >
              <b-form-group
                label="Ano"
                label-for="year"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Título"
                  rules="required"
                >
                  <b-form-select
                    v-model="yearSelected"
                    :options="years"
                    :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              xl="6"
              lg="6"
              md="12"
            >
              <b-form-group
                label="Mês"
                label-for="month"
              >
                <b-form-select
                  id="month"
                  v-model="monthSelected"
                  :options="months"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <div>
            <b-row
              v-for="(item) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="2">
                <b-form-group
                  label="Fundo"
                  label-for="type"
                >

                  <b-form-select
                    id="type"
                    v-model="item.fund"
                    disabled
                    :options="funds"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Sênior"
                  label-for="month"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Sênior"
                    rules="required|number"
                  >
                    <b-form-input
                      id="month"
                      v-model="item.senior"
                      :state="errors.length > 0 ? false:null"
                      :options="months"
                      placeholder="0.8"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group
                  label="CDI Ano Sênior"
                  label-for="month"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CDI Sênior"
                    rules="required"
                  >
                    <b-form-input
                      id="month"
                      v-model="item.cdi_year_senior"
                      :state="errors.length > 0 ? false:null"
                      :options="months"
                      placeholder="CDI + 4.5 A.A"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group
                  label="Mezanino"
                  label-for="value"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mezanino"
                    rules="required|number"
                  >

                    <b-form-input
                      id="value"
                      v-model="item.mezanino"
                      :state="errors.length > 0 ? false : null"
                      placeholder="0.9"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group
                  label="CDI Ano Mezanino"
                  label-for="month"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mezanino"
                    rules="required"
                  >
                    <b-form-input
                      id="month"
                      v-model="item.cdi_year_mezanino"
                      :options="months"
                      :state="errors.length > 0 ? false : null"
                      placeholder="CDI + 4.5 A.A"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

          </div>
        </b-card>
        <b-button
          :disabled="invalid"
          variant="primary"
          class="m-auto d-block"
          @click="formValidate"
        >
          Lançar
        </b-button>
      </b-form>
    </validation-observer>
  </b-container>
</template>

<script>
import {
    BForm, BFormSelect, BFormGroup, BFormInput, BRow, BCol, BButton, BCard, BContainer,
} from 'bootstrap-vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import PtahService from '@/service/ptah/register-fund';

export default {
    components: {
        BCard,
        BForm,
        BFormSelect,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BContainer,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            showLoading: false,
            items: [{
                id: 'd2bf8c1e-028c-4af8-951b-858466d88f31',
                senior: 0,
                mezanino: 0,
                fund: 'FIC',
            },
            {
                id: '793949ce-5db8-465f-9964-b305bf5b5ec4',
                senior: 0,
                mezanino: 0,
                fund: 'ViaInvest',
            },
            {
                id: 'a444fa03-6f7a-40f6-8e3e-b2820f01d970',
                senior: 0,
                mezanino: 0,
                fund: 'Multiplik',
            },
            ],
            nextTodoId: 2,
            yearSelected: 0,
            required,
            monthSelected: '1',
            months: [{ value: '1', text: 'Janeiro' },
                { value: '2', text: 'Fevereiro' },
                { value: '3', text: 'Março' },
                { value: '4', text: 'Abril' },
                { value: '5', text: 'Maio' },
                { value: '6', text: 'Junho' },
                { value: '7', text: 'Julho' },
                { value: '8', text: 'Agosto' },
                { value: '9', text: 'Setembro' },
                { value: '10', text: 'Outubro' },
                { value: '11', text: 'Novembro' },
                { value: '12', text: 'Dezembro' },
            ],
            funds: [{ value: 'FIC', text: 'FIC' },
                { value: 'Multiplik', text: 'Multiplik' },
                { value: 'ViaInvest', text: 'Viainvest' },
            ],

        };
    },
    computed: {
        years: () => {
            const date = new Date();
            const years = [];
            for (let year = date.getFullYear(); year >= date.getFullYear() - 1; year -= 1) {
                years.push({
                    text: year,
                    value: year,
                });
            }

            return years.reverse();
        },
    },
    mounted() {
        this.initTrHeight();
    },
    created() {
        this.monthSelected = this.actualMonths();
        window.addEventListener('resize', this.initTrHeight);
        const date = new Date();
        this.yearSelected = date.getFullYear();
    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight);
    },
    methods: {
        actualMonths() {
            const d = new Date();
            return String(d.getMonth());
        },
        repeateAgain() {
            this.items.push({
                id: this.nextTodoId += this.nextTodoId,
                type: 'mezanino',
                fund: 'fic',
                records: {
                    month: 'Janeiro',
                    value: 0,
                },
            });

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight);
            });
        },
        removeItem(index) {
            this.items.splice(index, 1);
            this.trTrimHeight(this.$refs.row[0].offsetHeight);
        },
        initTrHeight() {
            this.trSetHeight(null);
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight);
            });
        },
        formValidate() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.release();
                }
            });
        },
        async release() {
            const response = await this.confirmAnAction('Você deseja realmente lançar os fundos?');

            if (!response) return;

            const body = (this.items.reduce((acc, item) => {
                acc.push({
                    fund_id: item.id,
                    shareholder: 'Mezanino',
                    profitabilities: [
                        {
                            year: this.yearSelected,
                            month: this.monthSelected,
                            profitability: +item.mezanino,
                            cdi_year: item.cdi_year_mezanino,
                        },
                    ],
                });

                acc.push({
                    fund_id: item.id,
                    shareholder: 'Sênior',
                    profitabilities: [
                        {
                            year: this.yearSelected,
                            month: this.monthSelected,
                            profitability: +item.senior,
                            cdi_year: item.cdi_year_senior,
                        },
                    ],
                });

                return acc;
            }, []));
            this.callLoading(true);
            const allResponse = await Promise.all(body.map(item => PtahService.registerFund(item)));
            this.callLoading(false);
            const hasError = allResponse.some(item => item.status !== 201);

            if (hasError) {
                await this.modalError('Houve um erro ao lançar os fundos');
                return;
            }

            this.modalSuccess('Fundos lançados com sucesso');
        },

    },
};
</script>

<style lang="scss">
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
