var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain.apply(null, arguments)}}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"xl":"6","lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Ano","label-for":"year"}},[_c('validation-provider',{attrs:{"name":"Título","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.years,"state":errors.length > 0 ? false:null},model:{value:(_vm.yearSelected),callback:function ($$v) {_vm.yearSelected=$$v},expression:"yearSelected"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"xl":"6","lg":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Mês","label-for":"month"}},[_c('b-form-select',{attrs:{"id":"month","options":_vm.months},model:{value:(_vm.monthSelected),callback:function ($$v) {_vm.monthSelected=$$v},expression:"monthSelected"}})],1)],1)],1)],1),_c('b-card',[_c('div',_vm._l((_vm.items),function(item){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Fundo","label-for":"type"}},[_c('b-form-select',{attrs:{"id":"type","disabled":"","options":_vm.funds},model:{value:(item.fund),callback:function ($$v) {_vm.$set(item, "fund", $$v)},expression:"item.fund"}})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Sênior","label-for":"month"}},[_c('validation-provider',{attrs:{"name":"Sênior","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"month","state":errors.length > 0 ? false:null,"options":_vm.months,"placeholder":"0.8"},model:{value:(item.senior),callback:function ($$v) {_vm.$set(item, "senior", $$v)},expression:"item.senior"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"CDI Ano Sênior","label-for":"month"}},[_c('validation-provider',{attrs:{"name":"CDI Sênior","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"month","state":errors.length > 0 ? false:null,"options":_vm.months,"placeholder":"CDI + 4.5 A.A"},model:{value:(item.cdi_year_senior),callback:function ($$v) {_vm.$set(item, "cdi_year_senior", $$v)},expression:"item.cdi_year_senior"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Mezanino","label-for":"value"}},[_c('validation-provider',{attrs:{"name":"Mezanino","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value","state":errors.length > 0 ? false : null,"placeholder":"0.9"},model:{value:(item.mezanino),callback:function ($$v) {_vm.$set(item, "mezanino", $$v)},expression:"item.mezanino"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"CDI Ano Mezanino","label-for":"month"}},[_c('validation-provider',{attrs:{"name":"Mezanino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"month","options":_vm.months,"state":errors.length > 0 ? false : null,"placeholder":"CDI + 4.5 A.A"},model:{value:(item.cdi_year_mezanino),callback:function ($$v) {_vm.$set(item, "cdi_year_mezanino", $$v)},expression:"item.cdi_year_mezanino"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)]),_c('b-button',{staticClass:"m-auto d-block",attrs:{"disabled":invalid,"variant":"primary"},on:{"click":_vm.formValidate}},[_vm._v(" Lançar ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }