import methods from '@/enums/methods';
import Nerfetari from '../base/nerfetari';

class PtahService extends Nerfetari {
    async registerFund({
        shareholder,
        fund_id,
        profitabilities,
    }) {
        return this.fetch({
            url: '/fund/profitability',
            method: methods.POST,
            data: {
                shareholder,
                fund_id,
                profitabilities,
            },
        });
    }

    async registerSheet(obj) {
        const formData = new FormData();
        for (const [key, value] of Object.entries(obj)) {
            formData.append(key, value);
        }
        return this.fetch({
            url: '/fund/sheet',
            method: methods.POST,
            data: formData,
        });
    }
}

export default new PtahService();
